import {Fragment, useState} from "react";
import Contact from "../Contact/Contact";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

export default function Stand(props) {
    const [show, setShow] = useState(false);
    const [showCoworking, setShowCoworking] = useState(false);
    const stand = props.stand;

    const handleClick = () => {
        setShow(!show);
    };
    const handleClickCoworking = () => {
        setShowCoworking(!showCoworking);
    };

    return (
        <li className={`main_list ${show ? 'visible' : 'invisible'}`}>
            <button className='main_button' onClick={(e) => handleClick(e)}>
                <h2>
                    {stand.title}
                </h2>
            </button>
            <div className='main_list_content'>
                {stand.logo &&
                    <img src={stand.logo} alt={stand.title}/>
                }
                <p className='wysiwyg'
                    dangerouslySetInnerHTML={{
                        __html: stand.description
                    }}>
                </p>
                {stand.meet &&
                    <Fragment>
                        <button className='button button_white mt-2' onClick={(e) => handleClickCoworking()}>
                            Click here to start a conversation with us
                        </button>
                        {showCoworking &&
                            <iframe
                                className='mt-3'
                                title={stand.title}
                                style={{
                                    minHeight: '640px',
                                    border: 'none'
                                }}
                                src={`${stand.meet}?default`}
                                allow="camera; microphone; fullscreen; speaker; display-capture" />
                        }
                    </Fragment>
                }
                {stand.video.length > 0 &&
                    <Fragment>
                        <h3>Videos</h3>
                        {stand.video.map((video, index) =>
                            <p key={index} dangerouslySetInnerHTML={{__html: video.html}} />
                        )}
                    </Fragment>
                }
                {stand.files.length > 0 &&
                    <Fragment>
                        <h3>Attached documents</h3>
                        {stand.files.map((document, index) =>
                            <a className='button button_white button_light mb-2' target='_blank' rel="noreferrer" href={document.url} key={index} >{document.title} - <FontAwesomeIcon icon={faDownload} /></a>
                        )}
                    </Fragment>
                }
                {(stand.contact.length > 0 || stand.contact_description) &&
                    <Fragment>
                        <h3>Contact us</h3>
                        {stand.contact.length > 0 && stand.contact.map((contact, index) => <Contact contact={contact} key={index} />)}
                        <p className='wysiwyg'
                           dangerouslySetInnerHTML={{
                               __html: stand.contact_description
                           }}>
                        </p>
                    </Fragment>
                }
            </div>
        </li>
    )
};