import CloseMenu from "../Interface/CloseMenu";
import Bounce from 'react-reveal/Bounce';
import ReactPlayer from "react-player";

export default function CicgVideo(props) {
    const {event, videoCicg } = props;

    return (
        <Bounce right>
            <div id='event_about'>
                <div className='top_right_panel'>
                    <div className="left">
                        <div>
                            {event.date.txt}
                        </div>
                        <h1>
                            Watch our presentation
                        </h1>
                        <CloseMenu />
                    </div>
                </div>
                <ReactPlayer url={videoCicg} width='100%' controls={true} />
            </div>
        </Bounce>
    )
};