import Unity from "react-unity-webgl";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setBottomTitle,
    setContentRightPanel,
    setRightPanelBgColor,
    setRightPanelTitle, showAboutCicg, showAboutEvent, showCoworking, showExhibitors,
    showPrograms, showRightPanel, showVideoCicg
} from "../Interface/features/interfaceSlice";
import {getStand, prepareDescriptionHtml, prepareMeetHtml, prepareVideoHtml} from "../Stand/StandManager";
import Fade from 'react-reveal/Fade';

export default function UnityC(props) {
    const {unityContext, isLoaded, unityIsReady} = props;
    const stands = useSelector((state) => state.event.event.stands);
    const event = useSelector((state) => state.event.event);
    const dispatch = useDispatch();

    // Set Event name at the bottom
    useEffect(function() {
        dispatch(setBottomTitle(event.title))
    }, [event.title, unityContext, dispatch]);

    /** UNITY EVENT LISTENER **/
    useEffect(function () {
        unityContext.on("CommunicateStand", function (payload) {
            const stand = getStand(stands, payload);
            const color = stand.color;
            const meetHtml = prepareMeetHtml(stand);
            dispatch(setRightPanelBgColor(color));
            dispatch(setRightPanelTitle(stand.title));
            dispatch(setContentRightPanel(meetHtml));
        });
    }, [stands, unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("VisitWebsiteStand", function (payload) {
            const stand = getStand(stands, payload);
            const websiteUrl = stand.website.url;
            if (websiteUrl) {
                window.open(websiteUrl);
            }
        });
    }, [stands, unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("ShowProductsStand", function (payload) {
            const stand = getStand(stands, payload);
            const color = stand.color;
            const descriptionHtml = prepareDescriptionHtml(stand);
            dispatch(setRightPanelBgColor(color));
            dispatch(setRightPanelTitle(stand.title));
            dispatch(setContentRightPanel(descriptionHtml));
        });
    }, [stands, unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("WatchVideoStand", function (payload) {
            const stand = getStand(stands, payload);
            const color = stand.color;
            const videoHtml = prepareVideoHtml(stand);
            dispatch(setRightPanelBgColor(color));
            dispatch(setRightPanelTitle(stand.title));
            dispatch(setContentRightPanel(videoHtml));
        });
    }, [stands, unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("AreaChanged", function (payload) {
            dispatch(setBottomTitle(payload));
        });
    }, [unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("CommunicateCICG", function () {
            dispatch(showRightPanel());
            dispatch(showAboutCicg());
        });
    }, [unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("WatchVideoCICG", function (payload) {
            if (payload) {
                dispatch(showRightPanel());
                dispatch(showVideoCicg(payload));
            }
        });
    }, [unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("ShowExhibitorsOrganiser", function () {
            dispatch(showRightPanel());
            dispatch(showExhibitors());
        });
    }, [unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("ShowProgrammeOrganiser", function () {
            dispatch(showRightPanel());
            dispatch(showPrograms());
        });
    }, [unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("VisitCoworkingOrganiser", function () {
            dispatch(showRightPanel());
            dispatch(showCoworking());
        });
    }, [unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("ShowEventOrganiser", function () {
            dispatch(showRightPanel());
            dispatch(showAboutEvent());
        });
    }, [unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("StartCoworking", function () {
            dispatch(showRightPanel());
            dispatch(showCoworking());
        });
    }, [unityContext, dispatch]);

    useEffect(function () {
        unityContext.on("VisitEventOrganiser", function () {
            dispatch(showRightPanel());
            dispatch(showAboutEvent());
        });
    }, [unityContext, dispatch]);

    return (
        <Fade>
            <Unity
                className='unityCanvas'
                unityContext={unityContext}
                style={{
                    visibility: (isLoaded && unityIsReady) ? "visible" : "hidden"
                }}
            />
        </Fade>
    );
}
