import CloseMenu from "../Interface/CloseMenu";
import ShowMenu from "../Interface/ShowMenu";
import Bounce from 'react-reveal/Bounce';
import {Fragment} from "react";
import Contact from "../Contact/Contact";

export default function EventAbout(props) {
    const event = props.event;

    return (
        <Bounce right>
            <div id='event_about'>
                <div className='top_right_panel'>
                    <div className="left">
                        <div>
                            {event.date.txt}
                        </div>
                        <h1>
                            {event.title}
                        </h1>
                        <CloseMenu />
                    </div>
                </div>
                <div className="back_menu">
                    <ShowMenu />
                    <h2>
                        About the Event
                    </h2>
                </div>
                {event.logo &&
                    <img src={event.logo} alt={event.title}/>
                }

                {event.description &&
                    <Fragment>
                        <h3>Description</h3>
                        <div
                            className='wysiwyg'
                            dangerouslySetInnerHTML={{
                                __html: event.description
                            }}>
                        </div>
                    </Fragment>
                }

                {event.contact.length > 0 &&
                <Fragment>
                    <h3>Contact us</h3>
                    {event.contact.length > 0 && event.contact.map((contact, index) => <Contact contact={contact} key={index} />)}
                </Fragment>
                }

            </div>
        </Bounce>
    )
};