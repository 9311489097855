import {useDispatch, useSelector} from "react-redux";
import {
    showAboutCicg,
    showAboutEvent,
    showContact,
    showCoworking,
    showExhibitors,
    showPrograms
} from "./features/interfaceSlice";
import React, {Fragment} from "react";
import CloseMenu from "./CloseMenu";
import {unityContext} from "../App/App";

export default function Menu(props) {
    const dispatch = useDispatch();
    const event = useSelector((state) => state.event);

    const backHome = () => {
        unityContext.send("EventController", "backHome");
    }

    return (
        <div id='menu' className='front_of_canvas'>
            {event.isLoaded &&
                <Fragment>
                    <div className='top_right_panel'>
                        {event.isLoaded &&
                            <div className="left">
                                {event.event.date.txt &&
                                    <div>
                                        {event.event.date.txt}
                                    </div>
                                }
                                {event.event.title &&
                                  <h1>
                                      {event.event.title}
                                  </h1>
                                }
                            </div>
                        }
                        <CloseMenu />
                    </div>

                    <ul>
                        <li>
                            <span onClick={backHome}>
                                Welcome !
                            </span>
                        </li>

                        <li>
                            <button onClick={() => dispatch(showAboutEvent())} >About the event</button>
                        </li>

                        {event.event.programs.length > 0 ?
                        <li>
                            <button onClick={() => dispatch(showPrograms())} >Conferences program</button>
                        </li>
                        : null }

                        {event.event.stands.length > 0 ?
                            <li>
                                <button onClick={() => dispatch(showExhibitors())}>Exhibitors</button>
                            </li>
                        : null }

                        {event.event.coworkings.length > 0 ?
                            <li>
                                <button onClick={() => dispatch(showCoworking())}>Co-working</button>
                            </li>
                        : null }

                        {event.event.about_cicg &&
                            <li>
                                <button onClick={() => dispatch(showAboutCicg())} >About CICG</button>
                            </li>
                        }

                        {event.event.contact.length > 0 ?
                            <li>
                                <button onClick={() => dispatch(showContact())}>Contacts</button>
                            </li>
                        : null }
                    </ul>

                    <ul className='hide_desktop small_menu'>
                        <li>
                            <span>
                                @2021 CICG
                            </span>
                        </li>
                        <li>
                            <a href="https://cicg.ch/en/" target='_blank' rel='noreferrer'>
                                International Conference Centre Geneva (CICG)
                            </a>
                        </li>
                        <li>
                            <a href="https://cicg.ch/en/" target='_blank' rel='noreferrer'>
                                Terms of use and Privacy Policy
                            </a>
                        </li>
                    </ul>
                </Fragment>
            }
        </div>
    )
};
