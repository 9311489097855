import CloseMenu from "../Interface/CloseMenu";
import ShowMenu from "../Interface/ShowMenu";
import Bounce from 'react-reveal/Bounce';
import {ChatWidget} from "@papercups-io/chat-widget";
import {cicg_chat} from "../Interface/features/interfaceSlice";

export default function CicgAbout(props) {
    const event = props.event;

    return (
        <Bounce right>
            <div id='event_about'>
                <div className='top_right_panel'>
                    <div className="left">
                        <div>
                            {event.date.txt}
                        </div>
                        <h1>
                            {event.title}
                        </h1>
                        <CloseMenu />
                    </div>
                </div>
                <div className="back_menu">
                    <ShowMenu />
                    <h2>
                        About the CICG
                    </h2>
                </div>
                <div
                    className='wysiwyg'
                    dangerouslySetInnerHTML={{
                        __html: event.about_cicg
                    }}>
                </div>
                {event.chat.enable === '1' &&
                    <ChatWidget
                        token={cicg_chat.token}
                        inbox={cicg_chat.inbox}
                        title="Welcome to CICG chat"
                        subtitle="Ask us anything in the chat window below 😊"
                        primaryColor="#ABB8C3"
                        greeting="Hello, any questions for the CICG conference center?"
                        awayMessage="Sorry, we are not available at the moment."
                        newMessagePlaceholder="Start typing..."
                        showAgentAvailability={true}
                        agentAvailableText="We're online right now!"
                        agentUnavailableText="We're away at the moment."
                        requireEmailUpfront={false}
                        iconVariant="outlined"
                        baseUrl={cicg_chat.base_url}
                        iframeUrlOverride={cicg_chat.iframe_url}
                    />
                }
            </div>
        </Bounce>
    )
};