import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    showMenu: false,
    showCoworking: false,
    showContact: false,
    showRightPanel: false,
    showExhibitors: false,
    showAboutEvent: false,
    showAboutCicg: false,
    showPrograms: false,
    showVideoCicg: false,
};

export const colors = {
    'white': '#ffffff',
    'green': '#1BCA7C',
    'red': '#D8003F',
    'blue': '#067F92',
}

// TODO : change with the real CICG ACCOUNT
export const cicg_chat = {
    'token': '6b3e81ef-b66e-4fdf-aa0e-7b2a28479490',
    'inbox': '6da09562-06b9-4099-9ce5-00f2b21b0d2f',
    'base_url': 'https://cicg-chatbox-poc.ideative.host',
    'iframe_url': 'https://cicg-widget-poc.ideative.host'
}

// Kinda messy because we don't have specs :X
export const interfaceSlice = createSlice({
    name: 'interface',
    initialState: {
        initialState,
        videoCicg: null,
        bottomTitle: null,
        rightPanelContent: null,
        rightPanelTitle: null,
        rightPanelBgColor: colors.white,
    },
    reducers: {
        setContentRightPanel: (state, action) => {
            state.showMenu = false;
            state.showRightPanel = true;
            state.rightPanelContent = action.payload;
        },
        setRightPanelBgColor: (state, action) => {
            state.rightPanelBgColor = action.payload;
        },
        setRightPanelTitle: (state, action) => {
            state.rightPanelTitle = action.payload;
        },
        setBottomTitle: (state, action) => {
            state.bottomTitle = action.payload;
        },
        showRightPanel: (state) => {
            state.showRightPanel = true;
        },
        hideRightPanel: (state) => {
            state.showMenu = false;
            state.showRightPanel = false;
            state.showCoworking = false;
            state.showContact = false;
            state.showExhibitors = false;
            state.showAboutEvent = false;
            state.showPrograms = false;
            state.showAboutCicg = false;
            state.showVideoCicg = false;
            state.rightPanelTitle = null;
        },
        showMenu: (state) => {
            state.showMenu = true;
            state.showRightPanel = true;
            state.showCoworking = false;
            state.showContact = false;
            state.showExhibitors = false;
            state.showAboutEvent = false;
            state.showPrograms = false;
            state.showAboutCicg = false;
            state.showVideoCicg = false;
            state.rightPanelBgColor = colors.white;
        },
        showCoworking: (state) => {
            state.showMenu = false;
            state.showCoworking = true;
            state.rightPanelBgColor = colors.red;
        },
        showContact: (state) => {
            state.showMenu = false;
            state.showContact = true;
            state.rightPanelBgColor = colors.red;
        },
        showExhibitors: (state) => {
            state.showMenu = false;
            state.showExhibitors = true;
            state.rightPanelBgColor = colors.blue;
        },
        showPrograms: (state) => {
            state.showMenu = false;
            state.showPrograms = true;
            state.rightPanelBgColor = colors.blue;
        },
        showAboutEvent: (state) => {
            state.showMenu = false;
            state.showAboutEvent = true;
            state.rightPanelBgColor = colors.blue;
        },
        showVideoCicg: (state, action) => {
            state.showMenu = false;
            state.showVideoCicg = true;
            state.videoCicg = action.payload;
            state.rightPanelBgColor = colors.blue;
        },
        showAboutCicg: (state) => {
            state.showMenu = false;
            state.showAboutCicg = true;
            state.rightPanelBgColor = colors.red;
        },
    },
})

export const {
    showMenu,
    setContentRightPanel,
    showRightPanel,
    hideRightPanel,
    setRightPanelBgColor,
    showCoworking,
    showContact,
    setRightPanelTitle,
    showExhibitors,
    showAboutEvent,
    showPrograms,
    showAboutCicg,
    setBottomTitle,
    showVideoCicg
} = interfaceSlice.actions;

export default interfaceSlice.reducer;