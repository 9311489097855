import CloseMenu from "./CloseMenu";
import Bounce from 'react-reveal/Bounce';

export default function RightPanelContent(props) {

    return (
        <Bounce right>
            <div className='top_right_panel'>
                <div className="left">
                    <h1>
                        {props.title}
                    </h1>
                    <CloseMenu />
                </div>
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: props.html
                }}>
            </div>
        </Bounce>
    )
};
