import {useSelector} from "react-redux";
import Menu from "./Menu";
import RightPanelContent from "./RightPanelContent";
import CoworkingList from "../Coworking/CoworkingList";
import ContactList from "../Contact/ContactList";
import StandList from "../Stand/StandList";
import EventAbout from "../Event/EventAbout";
import Bounce from 'react-reveal/Bounce';
import ProgramList from "../Program/ProgramList";
import CicgAbout from "../Event/CicgAbout";
import CicgVideo from "../Event/CicgVideo";

export default function RightPanel(props) {
    const store = useSelector((state) => state);

    let content = <RightPanelContent title={store.interface.rightPanelTitle ? store.interface.rightPanelTitle : store.event.event.title} html={store.interface.rightPanelContent} />;

    // Not proud, but it's working well
    if (store.interface.showMenu) {
        content = <Bounce left><Menu /></Bounce>
    }

    if (store.interface.showCoworking) {
        content = <CoworkingList event={store.event.event} coworkings={store.event.event.coworkings} />
    }

    if (store.interface.showContact) {
        content = <ContactList title={store.event.event.title} contacts={store.event.event.contact} />
    }

    if (store.interface.showExhibitors) {
        content = <StandList event={store.event.event} stands={store.event.event.stands} />
    }

    if (store.interface.showPrograms) {
        content = <ProgramList event={store.event.event} />
    }

    if (store.interface.showAboutEvent) {
        content = <EventAbout event={store.event.event} />
    }

    if (store.interface.showAboutCicg) {
        content = <CicgAbout event={store.event.event} />
    }

    if (store.interface.showVideoCicg) {
        content = <CicgVideo event={store.event.event} videoCicg={store.interface.videoCicg} />
    }

    return (
        <div id='right_panel'
             className={`front_of_canvas ${store.interface.rightPanelBgColor === '#ffffff' ? 'bg_white' : null}`}
             style={{
                 backgroundColor: store.interface.rightPanelBgColor
             }}
        >
            { content }
        </div>
    )
};
