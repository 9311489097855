// select a stand by Id in all the stands
export const getStand = (stands, payload) => stands.find(item => parseInt(item.id) === parseInt(payload));

// prepare the HTML with stand meet for the right panel
export const prepareMeetHtml = (stand) => '<div class="content_right_panel"><h3>Contact us</h3>' +
    '<div class="wysiwyg">' + ((stand.contact_description != null) ? stand.contact_description : "" ) + '</div>'+
    '<iframe title= ' + stand.title + ' style="min-height:640px;border:none"; src=' + stand.meet + '?default allow="camera; microphone; fullscreen; speaker; display-capture"' +
    '</div>';

// prepare the video with stand video for the right panel
export const prepareVideoHtml = (stand) => '<div class="content_right_panel"><h3>Watch our video</h3>'
     + stand.video.map((video) => video.html) +
    '</div>';

// prepare the stand description for the right panel
export const prepareDescriptionHtml = (stand) => '<div class="content_right_panel"><h3>Discover more about our products and services</h3>' +
    (stand.logo != null ? '<img src='+stand.logo+' alt='+stand.title+'/>' : "" ) +
    '<div class="wysiwyg">' + stand.description + '</div> ' +
    (stand.files.length > 0 ? '<h3>Attached documents</h3>' + stand.files.map((file, index) => ('<a class="button button_white button_light mb-2" target="_blank" href='+file.url+' >Document number ' + (index + 1) + '</a>')) : "") +
    (stand.contact_description != null ? '<h3>Contact us</h3> <p class="wysiwyg"> '+stand.contact_description+'</p>' : "") +
    '</div>';