import { configureStore } from '@reduxjs/toolkit';
import appSliceReducer from './Modules/App/features/appSlice';
import eventSliceReducer from './Modules/Event/features/eventSlice';
import interfaceSliceReducer from './Modules/Interface/features/interfaceSlice';

import createSagaMiddleware from "redux-saga";
import rootSaga from './saga/sagas';

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
    reducer: {
        app: appSliceReducer,
        event: eventSliceReducer,
        interface: interfaceSliceReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);