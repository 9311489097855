import axios from 'axios';

export const drupalAPIBase = 'https://cicg-virtual-expo-admin.ideative.host';
export const fakeDataEndpoint = '/dummy/fakeEvent.json';

export const drupalAPIEndpoints = {
    'getCurrentEvent' : '/get_current_event',
    'getEvents' : '/events',
    'getEvent' : '/event/',
};

export const drupalAPIFormat = '_format=json';

export const API = axios.create({
    baseURL: drupalAPIBase,
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Accept': '*',
        'Content-Type': 'application/json; charset=UTF-8',
    }
});

export default API;