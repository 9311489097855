import {showMenu} from "./features/interfaceSlice";
import {useDispatch} from "react-redux";

export default function ShowMenu() {
    const dispatch = useDispatch();
    return (
        <button onClick={() => dispatch(showMenu())}>
            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M19.5272739,14.342916 C19.2813554,14.6743426 19.3086499,15.1446666 19.6091754,15.4452628 L23.3498291,19.1863054 L14.8136946,19.1863054 C14.3643035,19.1863054 14,19.5506089 14,20 C14,20.4493911 14.3643035,20.8136946 14.8136946,20.8136946 L23.3487442,20.8136946 L23.3487442,20.8136946 L19.6091754,24.5547372 C19.2785973,24.8853931 19.2786288,25.4214194 19.6092458,25.7520364 C19.9398639,26.0826545 20.4759018,26.0826545 20.8065198,25.7520364 L25.5996079,20.9589483 C26.1292205,20.4293358 26.1292205,19.5706642 25.5996079,19.0410517 L20.8065198,14.2479636 C20.4759018,13.9173455 19.9398639,13.9173455 19.6092458,14.2479636 L19.5272739,14.342916 Z" transform="matrix(-1 0 0 1 25.997 -14)" fill="#333"/></svg>
        </button>
    )
};