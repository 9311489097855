import {Fragment, useState} from "react";
import Moment from "react-moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export default function Program(props) {
    const [show, setShow] = useState(false);
    const program = props.program;
    const handleClick = (e) => {
        setShow(!show);
    };

    return (
        <li className={`main_list ${show ? 'visible' : 'invisible'}`}>
            <button className='main_button' onClick={(e) => handleClick(e)}>
                <h2>
                    <span className='date'>
                        {program.date_start &&
                        <Fragment>
                            <Moment format="DD/MM/YYYY HH:mm ">
                                {program.date_start}
                            </Moment>
                            to
                        </Fragment>
                        }
                        {program.date_end &&
                        <Moment format=" DD/MM/YYYY HH:mm">
                            {program.date_end}
                        </Moment>
                        }
                    </span>
                    <br/>
                    {program.title}
                </h2>
            </button>

            <div className='main_list_content'>
                {program.room &&
                <span>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> - {program.room}
                </span>
                }

                <p
                    dangerouslySetInnerHTML={{
                        __html: program.description
                    }}>
                </p>
            </div>

        </li>
    )
};