import { useDispatch } from "react-redux";
import {showMenu} from "./features/interfaceSlice";
import {Fragment} from "react";

export default function BurgerMenu(props) {
    const dispatch = useDispatch();

    return (
        <Fragment>
            <button id='burger_menu' className='front_of_canvas' onClick={() => dispatch(showMenu())}>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </Fragment>
    )
};