import {hideRightPanel} from "./features/interfaceSlice";
import {useDispatch} from "react-redux";

export default function CloseMenu() {
    const dispatch = useDispatch();
    return (
        <button className='close_menu' onClick={() => dispatch(hideRightPanel())}>
            <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M30.2587726,28.914456 L35.636039,34.292039 L41.0133053,28.914456 C41.3845281,28.5432333 41.9863991,28.5432333 42.3576219,28.914456 C42.7288447,29.2856788 42.7288447,29.8875499 42.3576219,30.2587726 L36.980039,35.636039 L42.3576219,41.0133053 C42.7288447,41.3845281 42.7288447,41.9863991 42.3576219,42.3576219 C41.9863991,42.7288447 41.3845281,42.7288447 41.0133053,42.3576219 L35.636039,36.980039 L30.2587726,42.3576219 C29.8875499,42.7288447 29.2856788,42.7288447 28.914456,42.3576219 C28.5432333,41.9863991 28.5432333,41.3845281 28.914456,41.0133053 L34.292039,35.636039 L28.914456,30.2587726 C28.5432333,29.8875499 28.5432333,29.2856788 28.914456,28.914456 C29.2856788,28.5432333 29.8875499,28.5432333 30.2587726,28.914456 Z" transform="translate(-28 -28)" fill="#333" fill-rule="evenodd"/></svg>
        </button>
    )
};