import { createSlice } from '@reduxjs/toolkit';

export const eventSlice = createSlice({
    name: 'event',
    initialState: {
        hasPassword: false,
        isLoaded: false,
        isLoading: false,
        isError: false,
        isErrorPassword: false,
        errorMessage: '',
        password: '',
        event : {
            id: '',
            name: '',
            type: '',
            date: '',
            logo: '',
            chat: '',
            stands: {

            },
            contact: {

            }
        },
    },
    reducers: {
        getEvent: (state) => {
            state.isLoading = true
        },
        getEventById: (state) => {
            state.isLoading = true
        },
        eventReceived: (state, action) => {
            state.event = action.payload;
            state.isLoaded = true;
            state.isLoading = false;
        },
        eventError: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isLoaded = true;
            state.errorMessage = action.payload;
        },
        enablePassword: (state, action) => {
            state.hasPassword = true;
            state.isErrorPassword = false;
        },
        disablePassword: (state, action) => {
            state.event = action.payload[0];
            state.password = action.payload[1];
            state.hasPassword = false;
            state.isErrorPassword = false;
            state.isLoaded = true;
            state.isLoading = false;
        },
        errorPassword: (state, action) => {
            state.isErrorPassword = true;
        }
    },
});

// Action creators are generated for each case reducer function

export const { eventReceived, enablePassword, disablePassword, errorPassword } = eventSlice.actions;

export default eventSlice.reducer;
