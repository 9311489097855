import Stand from "./Stand";
import CloseMenu from "../Interface/CloseMenu";
import ShowMenu from "../Interface/ShowMenu";
import Bounce from 'react-reveal/Bounce';

export default function StandList(props) {
    const {stands, event} = props;
    const list = stands.map((stand, index) => <Stand stand={stand} key={index} />);
    return (
        <Bounce right>
            <div id='stand_list' className='long_listing'>
                <div className='top_right_panel'>
                    <div className="left">
                        <h1>
                            {event.title}
                        </h1>
                        <CloseMenu />
                    </div>
                </div>
                <div className="back_menu">
                    <ShowMenu />
                    <h2>
                        All exhibitors
                    </h2>
                </div>
                <p>
                    You can find more informations about the exhibitors.
                </p>
                <ul>
                    { list }
                </ul>
            </div>
        </Bounce>
    )
};