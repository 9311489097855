import {useState} from "react";

export default function Coworking(props) {
    const [show, setShow] = useState(false);
    const coworking = props.coworking;

    const handleClick = () => {
        setShow(!show);
    };

    return (
        <li className={`main_list ${show ? 'visible' : 'invisible'}`}>
            <button className='white_button' onClick={(e) => handleClick()}>
                <h2>
                    {coworking.title}
                </h2>
            </button>
            <div className="main_list_content">
                {coworking.description &&
                    <p className='wysiwyg'
                       dangerouslySetInnerHTML={{
                           __html: coworking.description
                       }}>
                    </p>
                }
                {coworking.meet && show &&
                    <iframe
                        title={coworking.title}
                        style={{
                            minHeight: '640px',
                            border: 'none'
                        }}
                        src={`${coworking.meet}?default`}
                        allow="camera; microphone; fullscreen; speaker; display-capture" />
                }
            </div>
        </li>
    )
};