import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'store',
    initialState: {
        unityIsReady: false,
    },
    reducers: {
        startUnity: (state) => {
            state.unityIsReady = true;
        },
    },
})

export const { startUnity } = appSlice.actions;

export default appSlice.reducer;