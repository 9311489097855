import { useDispatch } from "react-redux";
import { hideRightPanel } from "./features/interfaceSlice";

export default function RightPanel(props) {
    const dispatch = useDispatch();

    return (
        <div id='overflow' className='front_of_canvas'>
            <button className='back' onClick={() => dispatch(hideRightPanel())}>
                <span>
                    Back to<br/>
                    explore
                </span>
            </button>
        </div>
    )
};