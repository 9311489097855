import CloseMenu from "../Interface/CloseMenu";
import ShowMenu from "../Interface/ShowMenu";
import Bounce from 'react-reveal/Bounce';
import Program from "./Program";

export default function ProgramList(props) {
    const {event} = props;
    const list = event.programs.map((program, index) => <Program program={program} key={index} />);

    const todayList = list.filter(function (item) {
        const program = item.props.program;
        // Modifying start and end date to bypass the hours
        const startDate = new Date(program.date_start).setHours(0, 0, 0, 0);
        const endDate = new Date(program.date_end).setHours(24, 59, 59, 0);
        const today = new Date();

        return today > startDate && today < endDate;
    });

    return (
        <Bounce right>
            <div id='program_list' className='long_listing'>
                <div className='top_right_panel'>
                    <div className="left">
                        <div>
                            {event.date.txt}
                        </div>
                        <h1>
                            {event.title}
                        </h1>
                        <CloseMenu />
                    </div>
                </div>
                <div className="back_menu">
                    <ShowMenu />
                    <h2>
                        Today's program
                    </h2>
                </div>

                {todayList.length > 0 ?
                    <ul>
                        { todayList }
                    </ul>
                    :
                    <p>
                        Today's program is empty.<br/>
                        Try to come back an other day.
                    </p>
                }
            </div>
        </Bounce>
    )
};