import Coworking from "./Coworking";
import CloseMenu from "../Interface/CloseMenu";
import ShowMenu from "../Interface/ShowMenu";
import Bounce from 'react-reveal/Bounce';

export default function CoworkingList(props) {
    const coworkings = props.coworkings;
    const list = coworkings.map((coworking, index) => <Coworking key={index} coworking={coworking} />);

    return (
        <Bounce right>
            <div id='coworking_list' className='long_listing'>
                <div className='top_right_panel'>
                    <div className="left">
                        <div>
                            {props.event.date.txt}
                        </div>
                        <h1>
                            {props.event.title}
                        </h1>
                        <CloseMenu />
                    </div>
                </div>
                <div className="back_menu">
                    <ShowMenu />
                    <h2>
                        Co-working
                    </h2>
                </div>
                <p>
                    Discover our co-working rooms below. You can join a room by cliking on the title.
                </p>
                <ul>
                    { list }
                </ul>
            </div>
        </Bounce>
    )
};