import Contact from "./Contact";
import CloseMenu from "../Interface/CloseMenu";
import ShowMenu from "../Interface/ShowMenu";
import Bounce from 'react-reveal/Bounce';

export default function ContactList(props) {
    const contacts = props.contacts;
    const list = contacts.map((contact, index) => <Contact contact={contact} key={index} />);
    return (
        <Bounce right>
            <div id='contact_list'>
                <div className='top_right_panel'>
                    <div className="left">
                        <CloseMenu />
                    </div>
                </div>
                <div className="back_menu">
                    <ShowMenu />
                    <h2>
                        Contact - {props.title}
                    </h2>
                </div>
                <p>
                    If you want to have more informations, don't hesitate to contact us.
                </p>
                <ul>
                    { list }
                </ul>
            </div>
        </Bounce>
    )
};