import { put, takeLatest, all } from 'redux-saga/effects';
import {API, drupalAPIEndpoints, drupalAPIFormat} from '../API/api';
import {
    eventReceived,
    enablePassword,
    disablePassword,
    errorPassword,
} from "../Modules/Event/features/eventSlice";
import {unityContext} from "../Modules/App/App";

function* unityReadyness($action) {
    yield console.log('unity readyness');
    if ($action.payload && $action.payload.password !== '') {
        unityContext.send("EventController", "sendPassword", $action.payload.password);
    }
}

/**
 * Generator function to get the current Event (without param in url)
 */
function* getEvent() {
    try {
        const event = yield API.get(drupalAPIEndpoints.getCurrentEvent + '?' + drupalAPIFormat).then(res => res.data);
        yield put(eventReceived(event));
        if (event.need_password !== undefined && event.need_password === true) {
           yield put(enablePassword());
        }
    } catch (e) {
         console.log('error', e);
         yield put({ type: "event/eventError", payload: 'Error when fetching the current event.' });
    }
}

/**
 * Generator function to fetch Event by ID
 */
function* getEventById($action) {
    try {
        const event = yield API.get(drupalAPIEndpoints.getEvent + $action.payload + '?' + drupalAPIFormat).then(res => res.data);
        yield put(eventReceived(event));
        if (event.need_password !== undefined && event.need_password === true) {
            yield put(enablePassword());
        }
    } catch (e) {
        console.log('error', e);
        yield put({ type: "event/eventError", payload: 'Error when fetching the event.' });
    }
}

/**
 * Generator function to fetch Event by ID with password
 */
function* getEventByPassword($action) {
    try {
        const event = yield API.get(drupalAPIEndpoints.getEvent + $action.payload.id_event + '?' + drupalAPIFormat + '&password=' + $action.payload.password).then(res => res.data);
        if (event.need_password === true && event.description !== undefined) {
            // Password is correct
            yield put(disablePassword([event, $action.payload.password]));
        } else {
            yield put(errorPassword());
        }
    } catch (e) {
        console.log('error', e);
        yield put({ type: "event/eventError", payload: 'Error when fetching the event.' });
    }
}

/**
 * Generator function which watch all sagas
 * @returns {Generator<SimpleEffect<"FORK", ForkEffectDescriptor<never>>, void, *>}
 */
function* allWatcher() {
    yield takeLatest('store/startUnity', unityReadyness);
    yield takeLatest('event/getEvent', getEvent);
    yield takeLatest('event/getEventById', getEventById);
    yield takeLatest('event/getEventByPassword', getEventByPassword);
}

export default function* rootSaga() {
    yield all([
        allWatcher()
    ])
}
